import { useRouter } from 'next/router';
import React, { FC } from 'react';

import imageHelper from '@/src/assets/helpers/imageHelper';
import { IDemo } from '@/src/assets/interfaces/themeInterface';

import { ITheme } from '../../assets/interfaces/themeInterface/index';
import Button from '../UI/button/Button';
import NextImage from '../UI/nextImage/NextImage';

import styles from './DemoCard.module.scss';

type TDemo = {
    demoData: IDemo;
    theme?: Partial<ITheme>;
    handleFilters?: (activeTag: string) => void;
    isThemePage?: boolean;
};

const DemoCard: FC<TDemo> = ({ demoData, theme, handleFilters, isThemePage }) => {
    const { demoName, demoTag, demoImage, demoPreview } = demoData;
    const router = useRouter();

    return (
        <>
            {theme && (
                <article className={styles.card}>
                    <div className={styles.card__wrapper}>
                        {demoImage ? (
                            <div className={styles.card__img}>
                                <NextImage
                                    src={imageHelper(demoImage).url}
                                    alt={demoName}
                                    fill
                                    sizes="(max-width: 480px) 90vw, (max-width: 768px) 44.3vw, 30vw"
                                />
                            </div>
                        ) : (
                            <div className={styles.backlog}></div>
                        )}

                        <div className={styles.hover__wrapper}>
                            {!router.query?.slug?.includes(`${theme.slug}`) && (
                                <Button
                                    buttonUrl={`/themes/${theme.slug}`}
                                    ariaLabel={`Learn More about ${theme.title} Theme`}
                                    className={styles.hover__learn}
                                    color="blue">
                                    Learn More
                                </Button>
                            )}
                            <Button
                                ariaLabel="Preview Theme"
                                buttonUrl={`${demoPreview}`}
                                className={styles.hover__preview}
                                color={'standard'}
                                target>
                                <span>Preview Theme</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M6.43674 5.19924L12.7123 5.28763M12.7123 5.28763L12.8007 11.5632M12.7123 5.28763L5.28769 12.7123"
                                        stroke="#0A163C"
                                        strokeWidth="1.7"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </Button>
                        </div>
                    </div>
                    <div className={styles.card__info}>
                        <div className={styles.info__top}>
                            {isThemePage ? (
                                <h5 className={`heading--5 ${styles.theme__name}`}>{demoName}</h5>
                            ) : (
                                <h5 className={`heading--5 ${styles.theme__name}`}>
                                    {theme.title}
                                </h5>
                            )}
                            <span
                                className={styles.demo__tag}
                                onClick={() => handleFilters && handleFilters(demoTag)}>
                                {demoTag}
                            </span>
                        </div>
                        {!isThemePage && <p className={`${styles.demo__name}`}>{demoName}</p>}
                    </div>
                </article>
            )}
        </>
    );
};

export default DemoCard;
